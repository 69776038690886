import React from "react";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import { FaPhone } from "react-icons/fa";
import isSafari from "is-safari";
import isIexplorer from "is-iexplorer";

const contactDetails = () => {
  return (
    <React.Fragment>
      <a
        href="tel:+3235350285"
        className="tracking-wide rounded-full text-sm py-4 px-5 bg-primary hover:bg-black text-black hover:text-primary uppercase inline-flex hover:border-black my-2 border-black border hover:border-primary"
      >
        <FaPhone
          className="pr-2"
          size="18px"
          onClick={() => this.scrollToRef(this.introRef)}
        />
        <div className="sm:block hidden">03 535 02 85</div>
        <div className="block sm:hidden">Bel ons</div>
      </a>
      <div className="text-xxs md:text-base">PLANTINLAAN 13, 2940 STABROEK</div>
    </React.Fragment>
  );
};

const Logo = ({ inverted, showBackground, title }) => {
  const extraClasses = showBackground ? "bg-black" : "";
  return (
    <div
      className={`logo text-primary p-5 sm:p-5 fixed w-full z-50 flex flex-wrap items-center justify-between ${extraClasses}`}
    >
      <Link to="/">
        <div className="text-xl sm:text-3xl uppercase font-bold">
          {!inverted && (
            <img
              src={"renovant-logo.svg"}
              alt="Renovant logo"
              className="w-48 sm:w-64"
            />
          )}
          {inverted && (
            <img
              src={"renovant-logo-inverted.svg"}
              alt="arrow"
              className="w-48 sm:w-64"
            />
          )}
        </div>
      </Link>
      {showBackground && (
        <div className="hidden lg:flex text-3xl uppercase">
          {isSafari || isIexplorer ? (
            <div>{title}</div>
          ) : (
            <Fade bottom>{title}</Fade>
          )}
        </div>
      )}
      {showBackground && (
        <div class="hidden sm:flex items-center flex-col">
          {isSafari || isIexplorer ? (
            <React.Fragment>
              <a
                href="tel:+3235350285"
                className="tracking-wide rounded-full text-sm py-4 px-5 bg-primary hover:bg-black text-black hover:text-primary uppercase inline-flex hover:border-black my-2 border-black border hover:border-primary"
              >
                <FaPhone
                  className="pr-2"
                  size="18px"
                  onClick={() => this.scrollToRef(this.introRef)}
                />
                <div className="sm:block hidden">03 535 02 85</div>
                <div className="block sm:hidden">Bel ons</div>
              </a>
              <div className="text-xxs md:text-base">
                PLANTINLAAN 13, 2940 STABROEK
              </div>
            </React.Fragment>
          ) : (
            <Fade right>
              <React.Fragment>
                <a
                  href="tel:+3235350285"
                  className="tracking-wide rounded-full text-sm py-4 px-5 bg-primary hover:bg-black text-black hover:text-primary uppercase inline-flex hover:border-black my-2 border-black border hover:border-primary"
                >
                  <FaPhone
                    className="pr-2"
                    size="18px"
                    onClick={() => this.scrollToRef(this.introRef)}
                  />
                  <div className="sm:block hidden">03 535 02 85</div>
                  <div className="block sm:hidden">Bel ons</div>
                </a>
                <div className="text-xxs md:text-base">
                  PLANTINLAAN 13, 2940 STABROEK
                </div>
              </React.Fragment>
            </Fade>
          )}
        </div>
      )}
    </div>
  );
};

export default Logo;
