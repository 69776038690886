import React from "react";
import { Link } from "gatsby";
import Footer from "../layout/Footer";
import Logo from "../components/Logo";
import {
    FaArrowLeft, FaChevronRight
} from "react-icons/fa";


const NotFound = () => (
    <main>
        <div className="container mx-auto py-5">
            <div className="text-4xl text-primary py-2 uppercase font-bold">
                <img
                    src={"renovant-logo.svg"}
                    alt="Renovant logo"
                    className="w-64"
                />
            </div>
            <div className="text-center">
                <div className="mb-10 text-5xl uppercase">
                    <h1>Oops</h1>
                </div>
                <div className="mb-10 text-3xl">
                    <p>Deze pagina is de weg kwijt.</p>
                    <p>Wil je terug naar de homepage gaan?</p>
                </div>


                    <a
                        className="rounded-full text-sm py-4 px-5 bg-primary hover:bg-black text-black hover:text-primary uppercase inline-flex no-underline mb-10"
                        href="/"
                    >
                        Ga naar de homepage
                        <span className="pl-3">
                          <FaChevronRight />
                        </span>
                    </a>
            </div>

        </div>

        <Footer />
    </main>
);

export default NotFound;


