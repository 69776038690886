import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer className="bg-black text-primary footer p-8">
      <div className="container mx-auto py-5 flex flex-col-reverse sm:flex-row">
        <div className="sm:w-4/5">
          <div>
            <div className="text-4xl text-primary py-5 uppercase font-bold">
              <img
                src={"renovant-logo.svg"}
                alt="Renovant logo"
                className="w-64"
              />
            </div>
            <span>in samenwerking met </span>
            <div className="text-4xl text-primary py-5 uppercase font-bold">
              <a href="http://timengels.be/">
                <img
                  src={"karweiwerken-timengels-logo.svg"}
                  alt="Karweiwerken Tim Engels logo"
                  className="w-24"
                />
              </a>
            </div>
          </div>
          <div className="text-xs footer-links">
            <Link to="/terms-and-conditions">Terms and conditions</Link> -{" "}
            <Link to="/cookie-policy">Cookie policy</Link>
          </div>
        </div>
        <div className="sm:w-1/5 flex justify-center flex-col text-lg">
          <div>
            <span>E</span>{" "}
            <a href="mailto:info@renovant.be">info@renovant.be</a>
          </div>
          <div>
            <span>T</span> 03/535.02.85
          </div>
          <div className="pt-3">BE0 730.548.966</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
